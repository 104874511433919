import React, { useEffect, useRef, useState } from 'react';
import styles from './PopupCard.module.scss';
import Heading from '@src/elements/Heading';
import { SVG_CLOSE } from '@src/types/svg';
import { PopupCardType } from '@src/types/map';
import classNames from 'classnames';
import { THEME_LILAC, THEME_ORANGE, THEME_BLUE } from '@src/types/themes';
import { ButtonLinkStandard } from '@src/components/ButtonLink';
import { ButtonIcon } from '@src/components/Button';
import MediaImage from '@src/components/MediaImage';

const PopupCard = ({
  map,
  position,
  title,
  image,
  slug,
  theme
}: PopupCardType) => {
  const [popup, setPopup] = useState<Popup>();
  const popupRef = useRef<HTMLDivElement>(null);

  /**
   * A customized popup on the map.
   */
  class Popup extends google.maps.OverlayView {
    position: google.maps.LatLng;
    containerDiv: HTMLDivElement;

    constructor(position: google.maps.LatLng, containerDiv: HTMLDivElement) {
      super();
      this.position = position;
      this.containerDiv = containerDiv;

      this.containerDiv = popupRef.current as HTMLDivElement;

      // Optionally stop clicks, etc., from bubbling up to the map.
      Popup.preventMapHitsAndGesturesFrom(this.containerDiv);
    }

    /** Called when the popup is added to the map. */
    onAdd() {
      if (!this.containerDiv) {
        return;
      }
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this.getPanes()!.floatPane.appendChild(this.containerDiv);
    }

    /** Called when the popup is removed from the map. */
    onRemove() {
      if (this.containerDiv.parentElement) {
        this.containerDiv.parentElement.removeChild(this.containerDiv);
      }
    }

    /** Called each frame when the popup needs to draw itself. */
    draw() {
      if (!this.getProjection() || !this.containerDiv || !this.position) {
        return;
      }
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const divPosition = this.getProjection().fromLatLngToDivPixel(
        this.position
      )!;

      // Hide the popup when it is far out of view.
      const display =
        Math.abs(divPosition.x) < 4000 && Math.abs(divPosition.y) < 4000
          ? 'block'
          : 'none';

      if (display === 'block') {
        this.containerDiv.style.left = divPosition.x + 'px';
        this.containerDiv.style.top = divPosition.y - 50 + 'px';
      }

      if (this.containerDiv.style.display !== display) {
        this.containerDiv.style.display = display;
      }
    }

    hide() {
      this.containerDiv.style.display = 'none';
    }
  }

  useEffect(() => {
    if (popupRef.current && map && position) {
      const newPopup = new Popup(position, popupRef.current);
      newPopup.setMap(map);
      newPopup.hide();
      setPopup(newPopup);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popupRef, map, position]);

  const hidePopup = () => {
    if (popup) {
      popup.setMap(null);
    }
  };

  return (
    <div ref={popupRef} className={styles.popupContainer}>
      <div className={styles.popupBubbleAnchor}>
        <div className={styles.popupBubble}>
          <div className={styles.mapCardContainer}>
            <div
              className={classNames(styles.mapCardTitle, {
                [styles.orangeBackground]: theme === THEME_ORANGE,
                [styles.blueBackground]: theme === THEME_BLUE,
                [styles.lilacBackground]: theme === THEME_LILAC
              })}
            >
              <Heading priority={4} fontStyle="h4">
                {title}
              </Heading>
              <ButtonIcon
                icon={SVG_CLOSE}
                color="black"
                fillVariant="outline"
                isBorderless={true}
                onClick={hidePopup}
              />
            </div>
            <div className={styles.mapCardHero}>
              {image && <MediaImage {...image} thumb />}
            </div>
            <div className={styles.mapCardCta}>
              {slug && (
                <ButtonLinkStandard fillVariant="outline" href={slug}>
                  View community
                </ButtonLinkStandard>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopupCard;
