import Heading from '@src/elements/Heading';
import {
  CtaFragment,
  GlobalSettingItemFragment,
  Maybe
} from '@src/graphql/gql-types';
import { COLOR_SECONDARY } from '@src/types/colors';
import classNames from 'classnames';
import React from 'react';
import styles from './HeroContent.module.scss';
import CTAButton from '@src/components/Button/CTAButton';
import { Row } from '@src/elements/Grid';
import { NationalEnquiryDynamicFormValues } from '@src/types/forms';
import EnquireButton from '../Header/EnquireButton';

interface HeroContent {
  heading?: string;
  description?: string;
  cta?: CtaFragment;
  slate?: boolean;
  displayTourBookingButton?: boolean;
  isCommunity?: boolean;
  dynamicFormValues?: NationalEnquiryDynamicFormValues;
  formDisclaimerRichText?: GlobalSettingItemFragment | undefined;
  headOfficePhoneNumber?: Maybe<string>;
}

const HeroContent: React.FC<HeroContent> = ({
  heading,
  description,
  cta,
  slate,
  displayTourBookingButton,
  isCommunity,
  formDisclaimerRichText,
  headOfficePhoneNumber
}) => {
  return (
    <div>
      <Heading
        priority={1}
        fontStyle="h1"
        className={classNames(styles.heroTitle, {
          [styles.headingSlate]: slate
        })}
      >
        {heading}
      </Heading>
      <Heading
        priority={2}
        fontStyle="h2"
        className={classNames(styles.heroDescription, {
          [styles.descriptionSlate]: slate
        })}
      >
        {description}
      </Heading>
      <Row directionXs="column" directionSm="row">
        {displayTourBookingButton && isCommunity && (
          <div className={styles.buttons}>
            <EnquireButton
              testid="bookATourButton"
              buttonText="Book a Tour"
              color={COLOR_SECONDARY}
              isCommunity={isCommunity}
              formDisclaimerRichText={formDisclaimerRichText}
              headOfficePhoneNumber={headOfficePhoneNumber}
            />
          </div>
        )}
        {cta && (
          <div className={styles.buttons}>
            <CTAButton
              color={COLOR_SECONDARY}
              cta={cta}
              label={cta.label || ''}
            />
          </div>
        )}
      </Row>
    </div>
  );
};

export default HeroContent;
