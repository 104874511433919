import React, { useEffect, useState } from 'react';
import { MapMarkerType } from '@src/types/map';
import MapMarkerImg from '@public/assets/map-marker.svg';

const Marker: React.FC<
  google.maps.marker.AdvancedMarkerElementOptions & {
    updatePopupCard?: (data: MapMarkerType) => void;
  } & MapMarkerType
> = (options) => {
  const [marker, setMarker] =
    useState<google.maps.marker.AdvancedMarkerElement>();

  useEffect(() => {
    if (!marker) {
      const markerInstance = new google.maps.marker.AdvancedMarkerElement();
      setMarker(markerInstance);
    } else {
      marker.addListener('click', () => {
        if (options.updatePopupCard) {
          const popupCardContent = {} as MapMarkerType;
          popupCardContent.position = options.position;
          popupCardContent.title = options.title;
          popupCardContent.image = options.image;
          popupCardContent.slug = options.slug;
          popupCardContent.theme = options.theme;

          options.updatePopupCard(popupCardContent);
        }
      });
    }

    // remove marker from map on unmount
    return () => {
      if (marker) {
        marker.map = null;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marker, options]);

  useEffect(() => {
    if (marker) {
      // new HTML element representing the marker's content
      const content = document.createElement('div');
      content.innerHTML = `<img src="${MapMarkerImg.src}" alt="marker" />`;

      // setting the markers content, position, title, and adding it to the specified map
      marker.content = content;
      marker.position = options.position;
      marker.title = options.title || '';
      marker.map = options.map || null;
    }
  }, [marker, options]);

  return null;
};

export default Marker;
