import React, { useEffect, useRef, useState, ReactElement } from 'react';
import MapWrapper from '@src/components/Map/MapWrapper/MapWrapper';
import styles from './Map.module.scss';
import MapMarker from '@src/components/Map/Marker/Marker';
import PopupCard from '@src/components/Map/PopupCard/PopupCard';
import { MapMarkerType, PopupCardType, MapProps } from '@src/types/map';
import { Status } from '@src/constants/map';
import { getPageUrlPath } from '@src/utils/url';
import LoadingState from '@src/components/LoadingState';
import Heading from '@src/elements/Heading';
import Icon from '@src/elements/Icon';

const GoogleMap: React.FC<MapProps> = ({
  onClick,
  onIdle,
  children,
  hasPopupCard,
  ...options
}) => {
  const mapRef = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<google.maps.Map>();
  const [popupCardData, setPopupCardData] = useState<PopupCardType>();

  useEffect(() => {
    if (mapRef.current && !map) {
      setMap(
        new window.google.maps.Map(mapRef.current, {
          mapId: `${process.env.NEXT_PUBLIC_GOOGLEMAPS_ID}`
        })
      );
    }
  }, [mapRef, map]);

  useEffect(() => {
    if (map) {
      map.setOptions(options);
    }
  }, [map, options]);

  useEffect(() => {
    if (map) {
      setPopupCardData((prevData) => {
        return { ...prevData, map };
      });
    }
  }, [map]);

  useEffect(() => {
    if (map) {
      ['click', 'idle'].forEach((eventName) =>
        google.maps.event.clearListeners(map, eventName)
      );
    }
  }, [map, onClick, onIdle]);

  useEffect(() => {
    // Zoom the map to fit all the markers in view, if no center coordinate is supplied
    if (map && children && !options.center) {
      const bounds = new google.maps.LatLngBounds();
      React.Children.map(children, (child) => {
        const position = child.props?.position;
        position && bounds.extend(position);
        map.fitBounds(bounds);
      });
    }
  }, [map, children, options.zoom, options.center]);

  const updatePopupCard = (data: MapMarkerType) => {
    if (data.position && map) {
      const {
        title,
        image,
        slug,
        theme,
        position: { lat, lng }
      } = data;

      const newPosition = new google.maps.LatLng(Number(lat), Number(lng));

      setPopupCardData({
        map: map,
        position: newPosition,
        title,
        image,
        slug,
        theme
      });

      map.panTo(newPosition);
      const bounds = new google.maps.LatLngBounds();
      bounds.extend(data.position);
      map.panToBounds(bounds, { bottom: 200, left: 200, right: 200, top: 420 });
    }
  };

  return (
    <>
      <div ref={mapRef} className={styles.mapWidget} />
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          // set the map prop on the child component
          return React.cloneElement(child, {
            map,
            updatePopupCard
          });
        }
      })}
      {hasPopupCard && popupCardData?.map && popupCardData?.position && (
        <PopupCard
          map={popupCardData.map}
          position={popupCardData?.position}
          title={popupCardData.title}
          image={popupCardData.image}
          slug={popupCardData.slug}
          theme={popupCardData.theme}
        />
      )}
    </>
  );
};

const Map = ({
  zoom,
  zoomControl,
  fullscreenControl,
  streetViewControl,
  mapTypeControl,
  hasPopupCard,
  mapData,
  center,
  maxZoom
}: {
  zoom?: number;
  zoomControl: boolean;
  fullscreenControl: boolean;
  streetViewControl: boolean;
  mapTypeControl: boolean;
  hasPopupCard: boolean;
  mapData: Record<string, any>;
  center?: google.maps.LatLngLiteral;
  maxZoom?: number;
}) => {
  const [markers, setMarkers] = useState<MapMarkerType[]>([]);

  const render = (status: Status): ReactElement => {
    if (status === Status.LOADING) {
      return (
        <div className={styles.status}>
          <Heading fontStyle="h4" priority={4}>
            Loading map...
          </Heading>
          <LoadingState />
        </div>
      );
    }
    if (status === Status.FAILURE) {
      return (
        <div className={styles.status}>
          <Heading fontStyle="h4" priority={4}>
            <Icon color="errorColor" icon="Bang" />
            Error: unable to load map
          </Heading>
        </div>
      );
    }
    return <></>;
  };

  const addMarkers = (data: Record<string, any>) => {
    const newMarkers: MapMarkerType[] = [];

    data.forEach((item: any) => {
      const marker: MapMarkerType = {};
      marker.position = { lat: item.latLon?.lat, lng: item.latLon?.lon };
      marker.title = item.title;
      marker.slug = getPageUrlPath(item);
      marker.image = item.mapAssets?.mediaItem;
      marker.theme = item.theme;
      newMarkers.push(marker);
    });

    setMarkers((markers): MapMarkerType[] => [...markers, ...newMarkers]);
  };

  mapData.length > 0 && markers.length === 0 && addMarkers(mapData);

  const getMarkers = () => {
    return markers.map((marker, index) => (
      <MapMarker
        key={index}
        position={marker.position}
        title={marker.title}
        image={marker.image}
        slug={marker.slug}
        theme={marker.theme}
      />
    ));
  };

  return (
    <MapWrapper
      apiKey={process.env.NEXT_PUBLIC_GOOGLEMAPS_API_KEY as string}
      render={render}
    >
      <GoogleMap
        clickableIcons={false}
        {...(zoom && { zoom: zoom })}
        zoomControl={zoomControl}
        fullscreenControl={fullscreenControl}
        streetViewControl={streetViewControl}
        mapTypeControl={mapTypeControl}
        hasPopupCard={hasPopupCard}
        center={center}
        maxZoom={maxZoom}
      >
        {getMarkers()}
      </GoogleMap>
    </MapWrapper>
  );
};

export default Map;
